import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 15px 0;
  padding: 15px;
  background: white;
  @media (max-width: 810px) {
    padding: 10px;
  }
`;

const Para = styled.p`
  line-height: 2em;
`;

const Card = ({ title, subtitle, desc, points }) => {
  return (
    <Container>
      <TextContainer>
        {title && <h1>{title}</h1>}
        {subtitle && <h2>{subtitle}</h2>}
        {desc && desc.map((d) => <Para>{d}</Para>)}
        {points && (
          <ul>
            {points.map((p) => (
              <li style={{ marginBottom: "10px" }}>{p}</li>
            ))}
          </ul>
        )}
      </TextContainer>
    </Container>
  );
};

export default Card;
