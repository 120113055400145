import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HamburgerMenu from "react-hamburger-menu";
import styled from "styled-components";

import "./NavBar.css";

const linkList = [
  {
    path: "/",
    label: "Home",
  },
  {
    path: "/about",
    label: "About Us",
  },
  {
    path: "/it",
    label: "IT Support",
  },
  {
    path: "/repair",
    label: "Repair Services",
  },
  {
    path: "/network",
    label: "Network Services",
  },
  {
    path: "/web",
    label: "Web Solution",
  },
  {
    path: "/contact",
    label: "Contact Us",
  },
];

const MobileMenu = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  background: #e9e9f3;
  @media (min-width: 700px) {
    display: none;
  }
`;

const NavBar = (props) => {
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname;
  const [isMenuOpen, setisMenuOpen] = useState(false);

  const handleActiveLink = (path) => {
    if (currentPath === path) {
      return "active";
    }
    return "";
  };

  return (
    <div>
      <MobileMenu onClick={() => setisMenuOpen(!isMenuOpen)}>
        <HamburgerMenu
          isOpen={isMenuOpen}
          // menuClicked={() => setisMenuOpen(!isMenuOpen)}
          width={18}
          height={15}
          strokeWidth={2}
          rotate={0}
          color="black"
          borderRadius={0}
          animationDuration={0.5}
        />
        <section style={{ marginLeft: "5px" }}>Menu</section>
      </MobileMenu>
      <ul className={`topnav ${!isMenuOpen ? "hide" : ""}`}>
        {linkList.map(({ path, label }, i) => (
          <li key={path} className={i + 1 === linkList.length ? "right" : ""}>
            <Link className={handleActiveLink(path)} to={path}>
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavBar;
