import React from "react";
import Card from "./common/Card";
import Title from "./common/Title";

const desc = [
  "Are you facing any kind of networking issue? Well, give us a call and our best technicians will be on the way. We have assembled a team of experts that are certified by some of the world’s best networking product manufacturing giants. No matter how complex the issues are, our team delivers the quickest and most reasonable solutions. We have partnered with some of the best-branded Router, Switch and Firewall manufacturers. Experience our premium services and call us today!",
  "Most people often ignore the Wireless LAN (WLAN) networks in their office setups and suffer from poor network issues. Once you employ our premium services, you will have a seamless experience in every corner of your office. We have partnered with some of the best-branded access point manufacturers. Our experts have extensive knowledge of all kinds of networks. We will make sure that every nook and corner of your setup receives the maximum signal along with the highest speed enable by your internet service provider.",
  "For the complex structured cabling and network setup, there is no better match than our team in the entire Dubai region. Our experts have been in the industry for more than a decade and their vast experience earns them the title of being the best in the business. We cater to all projects irrespective of the size and scale of the companies. Be it Cat-6 and fiber cable pulling, terminations, or splicing, we are the only ones you need to call. We can rearrange the existing networks or relocate them to a new location in the blink of an eye. Our clients get access to detailed network diagrams after commissioning. Our prompt and efficient services lay the foundation of your growing business.",
  "We not only ensure the safety of your data, but we protect your office space as well. Our technicians know the importance of a secure and strong network. That is why we also offer installation and maintenance of CCTV and Biometric systems for our clients. The safety and security of our clients is our foremost priority. We install the cameras in the best locations and place the cables neatly and hidden as much as possible.",
];

const Network = () => {
  return (
    <div>
      <Title title="Network Services" />
      <Card subtitle="Network Support" desc={[desc[0]]} />
      <Card subtitle="WLAN Support" desc={[desc[1]]} />
      <Card subtitle="Structured Cabling" desc={[desc[2]]} />
      <Card subtitle="CCTV and Biometric Installation" desc={[desc[3]]} />
    </div>
  );
};

export default Network;
