import React from "react";
import Card from "./common/Card";
import Title from "./common/Title";

const desc = [
  "Our experts at Fixpert offer laptop repair and services at the most affordable rates. If you are facing any issues such as laptop crashed, frozen, or hacked, slowdown or loading failure, compatibility issue, unwanted noise, etc., we will resolve it immediately. We use only genuine parts for replacement during repairs from the authorized brands and ensure data backups at all times.",
  "Printers are unavoidable devices in our day to day life. Hard copy files are mandatory for many purposes. Sometimes Printer breakdown effects your entire organization. Our experts repair printers of all make/models at the lowest price possible. We cater to every minor or sophisticated issue and guarantee 100% satisfaction for our customers. If there is any issue such as blurred copies, leakages, or blocked cartridge, we will ensure that your printer gets back to the optimum quality.",
  "Plotter is a sensitive device with complex mechanism. If the plotter in your organization is not working properly and you need to get it fixed immediately, get in touch with our experts. Besides taking care of the problem at hand, we also ensure cleaning and alignment of the cartridges for best quality printing when servicing the plotter.",
  "No one understands the value of customer data more than us. We have been working in the IT solutions industry for over a decade now. We know how important your data is and that is why our data recovery services are the best in the market. We leave no stone unturned to retrieve every bit of data from your hacked or broken devices. We have made successful recoveries from almost every kind of pen-drives, hard drives, NAS drives, and servers for our clients. We are also capable of recovering data from the challenging RAID systems. If you have come to us for help, we will provide the best and most affordable solutions to you.",
];

const Repair = () => {
  return (
    <div>
      <Title title="Repair Services" />
      <Card subtitle="Laptop repair" desc={[desc[0]]} />
      <Card subtitle="Printer repair" desc={[desc[1]]} />
      <Card subtitle="Plotter repair" desc={[desc[2]]} />
      <Card subtitle="Data recovery" desc={[desc[3]]} />
    </div>
  );
};

export default Repair;
