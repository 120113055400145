import React from "react";
import Card from "./common/Card";
import Title from "./common/Title";

const desc = [
  "Web Development – If you have any requirement for web development services in the Dubai region, your search ends here. We develop state-of-the-art web solutions customized according to your needs and budget. Our experienced team of professionals has worked with different industries and caters to all aspects of web development - ideating, designing, developing, and hosting websites. Your online presence attracts your audience and converts the potential leads. Our experts are committed to providing the best web development solutions to ensure maximum audience engagement and the best ROI on our services.",
  "Web Hosting – Besides the best web development solutions in the market, we also provide web hosting services to our clients. Our tried and tested superfast servers provide the optimum platform the specialized applications. The experts at Printer Repair DXB analyze your websites regularly and optimize them for top-notch speeds and seamless user experience. Once you employ our services, your website will have the best platform in the digital world.",
];

const Web = () => {
  return (
    <div>
      <Title title="Web Solution" />
      <Card subtitle="Web Development" desc={[desc[0]]} />
      <Card subtitle="Web Hosting" desc={[desc[1]]} />
    </div>
  );
};

export default Web;
