import React from "react";
import Card from "./common/Card";
import Title from "./common/Title";

const desc = [
  "Our team of experienced professionals are well-versed with both Windows and iOS Platforms. We can help you monitor and maintain the computer systems and networks on your premises. We also extend support in the efficiency and security of these systems. No matter where you store your data - local computer, server, cloud, or email – its safety is our sole priority.",
  "We understand it is difficult to maintain your complicated and high-end systems and networks. That is why we offer an affordable and reliable Annual Maintenance Contract. During the contract period, our technicians will take care of every little issue so you won’t have to worry about them ever. Besides the regular cleaning and updating of operating systems, software, emails, etc., we also ensure their complete protection with efficient safeguards and data backups.",
  "We cater to all kinds of repairs, like Desktop, Laptops, Printers, Servers, and industrial PCBs. Our technicians provide one-stop repair solutions for all kinds of products. We only use genuine parts for repairs from the authorized and established brands. Every repair from our outlet carries a warranty of six months for your convenience and peace of mind. We also ensure that your data remains in safe hands during the repairs.",
  "Our experts provide cloud solutions to clients including Microsoft office 365, Azure, AWS, and VM's. We keep in touch with the latest technology and our cloud solutions ensure that your data is secure from every potential threat.",
];

const It = () => {
  return (
    <div>
      <Title title="IT Support" />
      <Card subtitle="IT Support" desc={[desc[0]]} />
      <Card subtitle="AMC Support" desc={[desc[1]]} />
      <Card subtitle="Technical Support" desc={[desc[2]]} />
      <Card subtitle="Cloud Support" desc={[desc[3]]} />
    </div>
  );
};

export default It;
