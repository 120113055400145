import React from "react";
import Card from "./common/Card";

const desc = [
  "Our team of highly-qualified professionals has worked extensively in the field of Information Technology. With over 15 of experience in the industry, our experts have in-depth knowledge of all the aspects of IT services. We have established ourselves as the best IT solutions agency through our consistent efforts and passion for learning. Each member of our team enthusiastically stays in touch with the latest trends and technologies and ensures that our clients get access to top-notch services.",
  "Although our long list of clients can vouch for our dedication and personalized service, we always let our work speak for us. If you are facing any difficulty with your devices or network, all you need to do is get in touch with our team. Experience our premium services and give us an opportunity to earn your invaluable trust. You can bring any damaged or hacked device to our outlet and see our technicians do their magic. If you are unable to come to us due to any reason, do not worry. Give us a call and someone from our support team will collect your device from your doorstep (Free of Charge!). Your satisfaction is our only goal and we will leave no stone unturned to achieve that.",
];

const About = () => {
  return (
    <div>
      <Card title="About Us" desc={desc} />
    </div>
  );
};

export default About;
