import React from "react";
import NavBar from "./NavBar";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneSquare,
  faEnvelope,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../images/fixpert.jpg";

const HeaderContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const HeaderHero = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const TitleSection = styled.div`
  text-align: left;
  @media (max-width: 700px) {
    text-align: center;
  }
`;

const ContactSection = styled.div`
  display: flex;
  @media (max-width: 700px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContactSectionItem = styled.section`
  margin: 10px 0 0 15px;
  @media (max-width: 700px) {
    flex-direction: column;
    text-align: center;
    margin: 0 0 10px 0;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderHero>
        <TitleSection>
          <img src={Logo} alt="" style={{ height: "150px" }} />
        </TitleSection>
        <ContactSection>
          <ContactSectionItem>
            {/* <FontAwesomeIcon
              style={{ color: "#117a9f" }}
              icon={faPhoneSquare}
            />{" "}
            04 2583804 */}
          </ContactSectionItem>
          <ContactSectionItem>
            <FontAwesomeIcon style={{ color: "#117a9f" }} icon={faMobileAlt} />{" "}
            +971 551970070
          </ContactSectionItem>
          <ContactSectionItem>
            <FontAwesomeIcon style={{ color: "#117a9f" }} icon={faEnvelope} />{" "}
            Sales@fixpert.ae
          </ContactSectionItem>
        </ContactSection>
      </HeaderHero>
      <NavBar />
    </HeaderContainer>
  );
};

export default Header;
