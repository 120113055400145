import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header";

import Home from "./components/Home";
import About from "./components/About";
import It from "./components/It";
import Repair from "./components/Repair";
import Network from "./components/Network";
import Web from "./components/Web";
import Contact from "./components/Contact";
import styled from "styled-components";
import Footer from "./components/Footer";

import "./App.css";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  margin: 0 auto;
`;

const Container = styled.div`
  width: 100%;
  background: #fdfafa;
`;

function App() {
  return (
    <Router>
      <Header />
      <Container>
        <Wrapper>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/it">
              <It />
            </Route>
            <Route path="/repair">
              <Repair />
            </Route>
            <Route path="/network">
              <Network />
            </Route>
            <Route path="/web">
              <Web />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
          </Switch>
        </Wrapper>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
