import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

const ContactContainer = styled.div`
  margin: 0 auto;
`;
const Contact = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");
  const handleSubmit = () => {
    axios
      .post("https://fixpert.herokuapp.com/contact", {
        name: name,
        email: email,
        phone: phone,
        message: message,
      })
      .then(function (response) {
        if (response?.data?.success) {
          toast.success("Our executive will keep in touch with you!");
          setname("");
          setemail("");
          setphone("");
          setmessage("");
        } else {
          toast.error(
            "Oops! Something went wrong. Please send a mail to 'Sales@fixpert.ae' to contact us"
          );
        }
        console.log(response);
      });
  };
  return (
    <ContactContainer>
      <FormContainer>
        <h1>Contact</h1>
        <label>Name:</label>
        <input
          className="input-text"
          type="text"
          name="name"
          value={name}
          onChange={(e) => setname(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <label>Email:</label>
        <input
          className="input-text"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setemail(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <label>Phone:</label>
        <input
          className="input-text"
          type="phone"
          name="text"
          value={phone}
          onChange={(e) => setphone(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <label>Message:</label>
        <textarea
          className="input-text"
          name="message"
          style={{ height: "200px" }}
          value={message}
          onChange={(e) => setmessage(e.target.value)}
        >
          {message}
        </textarea>
        <button className="button-submit" onClick={() => handleSubmit()}>
          Submit
        </button>
        <ToastContainer />
      </FormContainer>
    </ContactContainer>
  );
};

export default Contact;
