import React from "react";
import Card from "./common/Card";
import Title from "./common/Title";

const desc1 = [
  "As the leading IT solutions provider in Dubai, our team of dedicated professionals is available 24x7 at your service. With an experience of more than a decade in the industry, we have a long list of satisfied clients who have been our partners on this journey. Our professional team has in-depth knowledge of dealing with all aspects of hardware, software, and cloud technologies.",
  "With our consistent support and maintenance solutions, we can assure you the peace of mind you rightly deserve. Our personalized and affordable service has earned the loyalty of our clients. If you have any requirement, be it desktop support, network support, or complex repairing issues, we will instantly take care of it. Once you partner with us, you will never have to search for guidance anywhere else. We provide all IT solutions under one roof.",
];

const points = [
  "SIX months warranty with every product repair",
  "ONLY Genuine parts from the Authorized Manufacturers",
  "Complementary cleaning of the products with each service",
  "FREE pick-ups and deliveries of the products",
  "Professional Assistance at the most competitive prices",
];

const Home = () => {
  return (
    <div>
      <Title title="Expert Technicians – Just a Call Away!" />
      <Card desc={desc1} />
      <Title title="Get in touch with us today and our customer support representative will be happy to help you." />
      <Card title="Why Us" points={points} />
    </div>
  );
};

export default Home;
